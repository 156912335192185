import createReactComponent from '../createReactComponent';
export default createReactComponent('bookmark', 'IconBookmark', [
  [
    'path',
    {
      d: 'M18 7v14l-6 -4l-6 4v-14a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4z',
      key: 'svg-0',
    },
  ],
]);
