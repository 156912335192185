import createReactComponent from '../createReactComponent';
export default createReactComponent('tags', 'IconTags', [
  [
    'path',
    {
      d: 'M3 8v4.172a2 2 0 0 0 .586 1.414l5.71 5.71a2.41 2.41 0 0 0 3.408 0l3.592 -3.592a2.41 2.41 0 0 0 0 -3.408l-5.71 -5.71a2 2 0 0 0 -1.414 -.586h-4.172a2 2 0 0 0 -2 2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M18 19l1.592 -1.592a4.82 4.82 0 0 0 0 -6.816l-4.592 -4.592',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 10h-.01', key: 'svg-2' }],
]);
