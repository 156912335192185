import createReactComponent from '../createReactComponent';
export default createReactComponent('user-square', 'IconUserSquare', [
  ['path', { d: 'M9 10a3 3 0 1 0 6 0a3 3 0 0 0 -6 0', key: 'svg-0' }],
  ['path', { d: 'M6 21v-1a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v1', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z',
      key: 'svg-2',
    },
  ],
]);
