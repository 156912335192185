import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-google', 'IconBrandGoogle', [
  [
    'path',
    {
      d: 'M20.945 11a9 9 0 1 1 -3.284 -5.997l-2.655 2.392a5.5 5.5 0 1 0 2.119 6.605h-4.125v-3h7.945z',
      key: 'svg-0',
    },
  ],
]);
