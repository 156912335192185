import createReactComponent from '../createReactComponent';
export default createReactComponent('route-square', 'IconRouteSquare', [
  ['path', { d: 'M3 17h4v4h-4z', key: 'svg-0' }],
  ['path', { d: 'M17 3h4v4h-4z', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M11 19h5.5a3.5 3.5 0 0 0 0 -7h-8a3.5 3.5 0 0 1 0 -7h4.5',
      key: 'svg-2',
    },
  ],
]);
