import createReactComponent from '../createReactComponent';
export default createReactComponent('network', 'IconNetwork', [
  ['path', { d: 'M6 9a6 6 0 1 0 12 0a6 6 0 0 0 -12 0', key: 'svg-0' }],
  [
    'path',
    { d: 'M12 3c1.333 .333 2 2.333 2 6s-.667 5.667 -2 6', key: 'svg-1' },
  ],
  [
    'path',
    { d: 'M12 3c-1.333 .333 -2 2.333 -2 6s.667 5.667 2 6', key: 'svg-2' },
  ],
  ['path', { d: 'M6 9h12', key: 'svg-3' }],
  ['path', { d: 'M3 20h7', key: 'svg-4' }],
  ['path', { d: 'M14 20h7', key: 'svg-5' }],
  ['path', { d: 'M10 20a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-6' }],
  ['path', { d: 'M12 15v3', key: 'svg-7' }],
]);
