import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'user-square-rounded',
  'IconUserSquareRounded',
  [
    ['path', { d: 'M12 13a3 3 0 1 0 0 -6a3 3 0 0 0 0 6z', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-1',
      },
    ],
    [
      'path',
      { d: 'M6 20.05v-.05a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v.05', key: 'svg-2' },
    ],
  ],
);
