import createReactComponent from '../createReactComponent';
export default createReactComponent('bookmark-filled', 'IconBookmarkFilled', [
  [
    'path',
    {
      d: 'M14 2a5 5 0 0 1 5 5v14a1 1 0 0 1 -1.555 .832l-5.445 -3.63l-5.444 3.63a1 1 0 0 1 -1.55 -.72l-.006 -.112v-14a5 5 0 0 1 5 -5h4z',
      fill: 'currentColor',
      key: 'svg-0',
      strokeWidth: '0',
    },
  ],
]);
