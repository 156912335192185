import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'triangle-inverted-filled',
  'IconTriangleInvertedFilled',
  [
    [
      'path',
      {
        d: 'M20.118 3h-16.225a2.914 2.914 0 0 0 -2.503 4.371l8.116 13.549a2.917 2.917 0 0 0 4.987 .005l8.11 -13.539a2.914 2.914 0 0 0 -2.486 -4.386z',
        fill: 'currentColor',
        key: 'svg-0',
        strokeWidth: '0',
      },
    ],
  ],
);
