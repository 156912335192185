import createReactComponent from '../createReactComponent';
export default createReactComponent('calendar-month', 'IconCalendarMonth', [
  [
    'path',
    {
      d: 'M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3v4', key: 'svg-1' }],
  ['path', { d: 'M8 3v4', key: 'svg-2' }],
  ['path', { d: 'M4 11h16', key: 'svg-3' }],
  ['path', { d: 'M7 14h.013', key: 'svg-4' }],
  ['path', { d: 'M10.01 14h.005', key: 'svg-5' }],
  ['path', { d: 'M13.01 14h.005', key: 'svg-6' }],
  ['path', { d: 'M16.015 14h.005', key: 'svg-7' }],
  ['path', { d: 'M13.015 17h.005', key: 'svg-8' }],
  ['path', { d: 'M7.01 17h.005', key: 'svg-9' }],
  ['path', { d: 'M10.01 17h.005', key: 'svg-10' }],
]);
