import createReactComponent from '../createReactComponent';
export default createReactComponent('photo-scan', 'IconPhotoScan', [
  ['path', { d: 'M15 8h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 13l2.644 -2.644a1.21 1.21 0 0 1 1.712 0l3.644 3.644',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M13 13l1.644 -1.644a1.21 1.21 0 0 1 1.712 0l1.644 1.644',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M4 8v-2a2 2 0 0 1 2 -2h2', key: 'svg-3' }],
  ['path', { d: 'M4 16v2a2 2 0 0 0 2 2h2', key: 'svg-4' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v2', key: 'svg-5' }],
  ['path', { d: 'M16 20h2a2 2 0 0 0 2 -2v-2', key: 'svg-6' }],
]);
