import createReactComponent from '../createReactComponent';
export default createReactComponent('login-2', 'IconLogin2', [
  [
    'path',
    {
      d: 'M9 8v-2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 12h13l-3 -3', key: 'svg-1' }],
  ['path', { d: 'M13 15l3 -3', key: 'svg-2' }],
]);
