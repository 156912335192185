import createReactComponent from '../createReactComponent';
export default createReactComponent('network-off', 'IconNetworkOff', [
  [
    'path',
    {
      d: 'M6.528 6.536a6 6 0 0 0 7.942 7.933m2.247 -1.76a6 6 0 0 0 -8.427 -8.425',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M12 3c1.333 .333 2 2.333 2 6c0 .337 -.006 .66 -.017 .968m-.55 3.473c-.333 .884 -.81 1.403 -1.433 1.559',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M12 3c-.936 .234 -1.544 1.29 -1.822 3.167m-.16 3.838c.116 3.029 .776 4.695 1.982 4.995',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M6 9h3m4 0h5', key: 'svg-3' }],
  ['path', { d: 'M3 20h7', key: 'svg-4' }],
  ['path', { d: 'M14 20h7', key: 'svg-5' }],
  ['path', { d: 'M10 20a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-6' }],
  ['path', { d: 'M12 15v3', key: 'svg-7' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-8' }],
]);
