import createReactComponent from '../createReactComponent';
export default createReactComponent('login', 'IconLogin', [
  [
    'path',
    {
      d: 'M15 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M21 12h-13l3 -3', key: 'svg-1' }],
  ['path', { d: 'M11 15l-3 -3', key: 'svg-2' }],
]);
